import React from 'react';
import Section from 'components/organisms/Section/Section';
import SectionAlternate from 'components/organisms/SectionAlternate/SectionAlternate';
import { Faq, Main, Support, Vacation } from './components';

import { pricings, faq } from './data';

const Pricing = () => {
  return (
    <div>
      <Main data={pricings} />
      <SectionAlternate>
        <Support />
      </SectionAlternate>
      <Section>
        <Faq data={faq} />
      </Section>
    </div>
  );
};

export default Pricing;
