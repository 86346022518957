export const pricings = [
  {
    title: '30 day trial license',
    subtitle: 'No obligation free trial',
    monthly: 49,
    annual: 480,
    licenseType: 0,
    productId: 1234,
    priceSuffix: ' / MO',
    features: [
      'Trial license',
      '30 day Duration',
      'Node Locked',
       'All base modules',
 'e-mail support',
    ],
    disclaimer: 'Fully featured 30-day free trial',
    isHighlighted: true,
  },
  {
    title: '1 Year Academic',
    subtitle: '1 year research license',
    monthly: 49,
    annual: 480,
    licenseType: 0,
    productId: 634297,
    priceSuffix: ' / MO',
    features: [
      'Regular updates to VRGS',
      'E-mail support',
      'Node locked license',
    ],
    disclaimer: 'Secure checkout by www.paddle.com',
    isHighlighted: true,
  },
  {
    title: '4 Year PhD License',
    subtitle: 'Duration of a typical PhD project',
    monthly: 79,
    annual: 780,
    licenseType: 1,
    productId: 634546,
    priceSuffix: ' / MO',
    features: [
      'Regular updates to VRGS',
      'E-mail support',
      'Node locked license',
    ],
    disclaimer: 'Secure checkout by www.paddle.com',
    isHighlighted: true,
  },
  {
    title: 'Academic Departmental',
    subtitle: '1 license for a whole department',
    monthly: 69,
    annual: 680,
    licenseType: 2,
    productId: 634547,
    priceSuffix: ' / MO',
    features: [
      'Regular updates to VRGS',
      'E-mail and video-call support',
      'Up to 50 seats',
      'For teaching and research',
    ],
    disclaimer: 'Secure checkout by www.paddle.com',
    isHighlighted: true,
  },
];

export const faq = [
  {
    id: 'faq-1',
    title: 'Is your checkout secure?.',
    subtitle: '',
    text:
      'Yes - our checkout is provided by paddle.com, a leading Revenue Delivery Platform. Our site is, of course, ssl encryted as well.',
    link: '',
  },
  {
    id: 'faq-2',
    title: 'How long will it take to get my license once I have paid?',
    subtitle: '',
    text:
      'We endeavour to send out licenses as quickly as possible. At the moment our license issuing process is manual, so you can expect you license within 24 hours on a working day, longer at weekends.',
    link: '',
  },
  {
    id: 'faq-3',
    title: 'I am unable to use the MSIX approach to installation. What can I do?',
    subtitle: '',
    text:
      'If your admin will not allow side-loading of apps or the MSIX installer then we can provide you with a standard MSI. Just contact us for the link.',
    link: '',
  },
];
