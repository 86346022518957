import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import Grid from '@mui/material/Grid/Grid';
import Typography from '@mui/material/Typography/Typography';
import Button from '@mui/material/Button/Button';
import SVGImage from 'components/atoms/SVGImage/SVGImage';
import SectionHeader from 'components/molecules/SectionHeader/SectionHeader';
import Section from 'components/organisms/Section/Section';
import CardPricingStandard from 'components/organisms/CardPricingStandard/CardPricingStandard';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  pagePaddingTop: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
    },
  },
  sectionContainer: {
    backgroundColor: theme.palette.primary.dark,
    paddingBottom: theme.spacing(20),
    borderRadius: '0 0 100% 0',
  },
  textWhite: {
    color: 'white',
  },
  fontWeightBold: {
    fontWeight: 'bold',
  },
  toggleContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(0, 2),
  },
  toggleButtonGroup: {
    background: 'transparent',
  },
  toggleButton: {
    background: 'transparent',
    border: '1px solid white',
    padding: theme.spacing(1, 5),
  },
  toggleButtonActive: {
    backgroundColor: 'white !important',
  },
  toggleTitle: {
    textTransform: 'capitalize',
  },
  toggleTitleActive: {
    color: theme.palette.primary.main,
  },
  pricingContainer: {
    position: 'relative',
    marginTop: theme.spacing(-20),
  },
  sectionNoPaddingTop: {
    paddingTop: 0,
  },
  cardPricing: {
    '& .countup-number__count-wrapper': {
      textAlign: 'left',
      marginBottom: 0,
      fontWeight: 'bold',
    },
  },
}));

const Main = (props) => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [pricingOption1, setPricingOption1] = useState({
    gross: '###',
    net: '',
    tax: '',
    tax_included: true,
  });
  const [pricingOption2, setPricingOption2] = useState({
    gross: '###',
    net: '',
    tax: '',
    tax_included: true,
  });
  const [pricingOption3, setPricingOption3] = useState({
    gross: '###',
    net: '',
    tax: '',
    tax_included: true,
  });
  const [theVersion, setEventData] = useState({});
  const openCheckout = (event, licenseType) => {
    window.Paddle.Setup({ vendor: process.env.PADDLE_VENDOR_KEY | 0 });
    window.Paddle.Checkout.open({ product: licenseType });
  };

  const buttonSwitch = (param) => {
    switch (param) {
      case 1234:
        return (
          <Button
            color="primary"
            variant={'contained'}
            fullWidth
            size="large"
            href="/get-trial"
          >
            Click Here for Free Trial
          </Button>
        );
        break;
      default:
        return (
          <Button
            color="primary"
            variant="contained"
            fullWidth
            size="large"
            onClick={(e) => openCheckout(e, param)}
          >
            Click here to buy now
          </Button>
        );
    }
  };

  React.useEffect(() => {
    function getCosts1(prices) {
      setPricingOption1(prices.price);
    }
    function getCosts2(prices) {
      setPricingOption2(prices.price);
    }
    function getCosts3(prices) {
      setPricingOption3(prices.price);
    }
    if (window.Paddle) {
      window.Paddle.Product.Prices(
        process.env.PADDLE_PRODUCT_ACAD_KEY,
        getCosts1,
      );
      window.Paddle.Product.Prices(
        process.env.PADDLE_PRODUCT_PHD_KEY,
        getCosts2,
      );
      window.Paddle.Product.Prices(
        process.env.PADDLE_PRODUCT_DEPT_KEY,
        getCosts3,
      );
    } else setPricingOption3([]);
  }, [pricingOption1, pricingOption2, pricingOption3]);

  const renderSwitch = (param) => {
    switch (param) {
      case Number(process.env.PADDLE_PRODUCT_ACAD_KEY):
        return renderPrice(pricingOption1);
      case Number(process.env.PADDLE_PRODUCT_PHD_KEY):
        return renderPrice(pricingOption2);
      case Number(process.env.PADDLE_PRODUCT_DEPT_KEY):
        return renderPrice(pricingOption3);
      case 1234:
        return 'FREE';
      default:
        return '####';
    }
  };

  return (
    <div className={className} {...rest}>
      <div className={classes.sectionContainer}>
        <Section narrow className={classes.pagePaddingTop} align="center">
          <SectionHeader
            title="Academic Licensing"
            subtitle="Founded by a leading academic and researcher in the fields of digital outcrop modelling, VRGeoscience is committed to supporting academics, researchers and students at all levels with significantly discounted licensing options. "
            titleProps={{
              className: clsx(classes.textWhite, classes.fontWeightBold),
              variant: 'h2',
            }}
            subtitleProps={{
              className: classes.textWhite,
            }}
            data-aos="fade-up"
          />
          <Button
            variant="contained"
            color="secondary"
            component="a"
            target="blank"
            href="/pricing-commercial"
            className={classes.listItemButton}
          >
            Click Here for Commercial Licensing
          </Button>
        </Section>
      </div>
      <div className={classes.pricingContainer}>
        <div>
          <Section className={classes.sectionNoPaddingTop}>
            <Grid container spacing={isMd ? 4 : 2}>
              {data.map((item, index) => (
                <Grid item xs={12} md={4} data-aos="fade-up" key={index}>
                  <CardPricingStandard
                    variant="outlined"
                    withShadow={item.isHighlighted ? true : false}
                    title={item.title}
                    liftUp
                    subtitle={item.subtitle}
                    priceComponent={<div>{renderSwitch(item.productId)}</div>}
                    features={item.features}
                    featureCheckComponent={
                      <SVGImage
                        src="/images/svg/check-circle.svg"
                        fontIconColor={theme.palette.primary.main}
                      />
                    }
                    cta={buttonSwitch(item.productId)}
                    disclaimer={item.disclaimer}
                    className={classes.cardPricing}
                  />
                </Grid>
              ))}
            </Grid>
          </Section>
        </div>
      </div>
    </div>
  );
};
Main.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Main;

function renderPrice(pricingOption1) {
  return (
    <div>
      <Typography variant="h3" color="textPrimary">
        {pricingOption1.gross}
      </Typography>
      <Typography variant="h5" color="textPrimary">
        ({pricingOption1.net}+VAT)
      </Typography>
    </div>
  );
}
